<template>
    <div class="grid grid-cols-2 gap-2 print:grid-cols-1">
        <div>
            <Filters class="print:hidden" @change="getAccruals" />
            <div class="card-tail">
                <div class="mb-16">
                    <table class="w-full mb-10">
                        <tr>
                            <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Status</th>
                            <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Startdatum</th>
                            <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Slutdatum</th>
                            <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningskonto</th>
                            <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Originalbelopp</th>
                            <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Restbelopp</th>
                            <th />
                        </tr>
                        <tr v-for="accrual in accruals" :key="accrual.accrualId" @click="selectAccrual(accrual.accrualId)">
                            <td class="border px-2 py-1 text-xs">{{ accrual.status }}</td>
                            <td class="border px-2 py-1 text-xs">{{ formatDate(accrual.startDate) }}</td>
                            <td class="border px-2 py-1 text-xs">{{ formatDate(accrual.endDate) }}</td>
                            <td class="border px-2 py-1 text-xs">{{ accrual.bookingAccount }}</td>
                            <td class="border px-2 py-1 text-xs" align="right">{{ accrual.originalAmount | swedishNumberFormat }}</td>
                            <td class="border px-2 py-1 text-xs" align="right">{{ accrual.restAmountSum | swedishNumberFormat }}</td>
                            <td class="border px-2 py-1 text-xs" width="33"><i class="far fa-paperclip" /></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <AccrualDetails :client-id="clientId" :selected-accrual-id="selectedAccrualId" class="print:hidden" />
    </div>
</template>
<script>
import moment from "moment";
import queryString from "query-string";
import Api from "./lopande_accrual.api";

export default {
    props: {
        clientId: {
            type: String,
            default: null,
        },
    },

    components: {
        AccrualDetails: () => import(/* webpackChunkName: "LopandeAccrualDetails" */ "./LopandeAccrualDetails"),
        Filters: () => import(/* webpackChunkName: "LopandeAccrualFilters" */ "./LopandeAccrualFilters"),
    },

    data() {
        return {
            accruals: [],
            selectedAccrualId: null,
        };
    },

    created() {
        this.getAccruals();
    },

    methods: {
        async getAccruals(filterEndDate = "", includeCompleted = false) {
            const { startDate } = queryString.parse(location.search);
            const { endDate } = queryString.parse(location.search);
            this.accruals = await Api.getByClient(this.clientId, filterEndDate, includeCompleted, startDate, endDate);
            console.log(this.accruals);
        },

        selectAccrual(id) {
            this.selectedAccrualId = id;
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        rowClicked({ accrualId }) {
            this.selectedAccrualId = accrualId;
        },

        getSummaries(param) {
            const { columns, data } = param;
            console.log(columns[6], data);
        },
    },
};
</script>
