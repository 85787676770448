import Vue from "vue";

export default {
    getByClient: async (clientId, filterEndDate, includeCompleted, startDate, endDate) => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/accruals`, {
                params: {
                    filterEndDate,
                    includeCompleted,
                    startDate,
                    endDate,
                },
            });
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: lopande_accrual.api.js ~ line 9 ~ getByClient: ~ error", error);
            return [];
        }
    },

    getDetails: async (clientId, id) => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/accruals/${id}`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: lopande_accrual.api.js ~ line 9 ~ getDetails: ~ error", error);
            return [];
        }
    },

    cancelAccrual: async (clientId, id) => {
        try {
            const response = await Vue.axios.put(`clients/{${clientId}}/accruals/${id}/abort`);
            return response;
        } catch (error) {
            console.log("🚀 ~ file: lopande_accrual.api.js ~ line 9 ~ getDetails: ~ error", error);
            return [];
        }
    },
};
